import React, { useState, useEffect } from 'react';
import { TokenStorage } from '../list';
import '../index.css';
import { Link } from "react-router-dom";
import MainNavbar from './mainNavbar';
import {useNavigate} from 'react-router-dom';
import fetchPlus from './network/fetchPlus';
import RingLoader from "react-spinners/RingLoader";

const ShoppingList = (props) => {
  const navigate = useNavigate();
  const [forecasts, setForecasts] = useState([]);
  const [token] = useState(TokenStorage.getToken());
  const [loading, setLoading] = useState(true);
  const [offline, setOffline] = useState(false);

  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

  useEffect(() => {
    const populateWeatherData = async () => {

          const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`},
          };
          var [status,body] = await fetchPlus('https://api.milionsmakow.pl/list', requestOptions);
          if (status===401) navigate('/auth');
          if (status===200) {
            const data =  body;
            setForecasts(data.sort((a, b) => a.name > b.name? 1 : -1));
            localStorage.setItem('Lists', JSON.stringify(data.sort((a, b) => a.name > b.name? 1 : -1)));
            setLoading(false);
          }
          if (status==='offline') {
            setOffline(true);
            setForecasts(JSON.parse(localStorage.getItem('Lists')));
            setLoading(false);
          }
      }

      if (token==null||token==='') 
      {
        navigate('/auth');
        return;
      }
      populateWeatherData();
    },[navigate, token]);

    /*useEffect(() => {
      localStorage.setItem('Lists', JSON.stringify(forecasts));
    }, [forecasts]);*/

    const addItem = async (name) => {
      var list = forecasts;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`},
        body: JSON.stringify({ Name: name })
      };
      var response = await fetch(`https://api.milionsmakow.pl/list`, requestOptions);
      list = list.concat(await response.json());
      setForecasts(list);
    };



    if (loading) 
    return <div style={{textAlign:"center", color: "black"}}>
      Wczytywanie danych 
      <RingLoader color={'#ec645b'} cssOverride={style} loading={loading} size={150} />
    </div>
      return (<>
        <MainNavbar AddItem={addItem}/>
        {(offline)?<div style={{textAlign:"center", color: "black"}}>Offline</div>:''}
        <div className='app-background'>
        <div className='main-container' style={{maxWidth:"500px", width:"100%"}}>
          <div className='item-list'>
            {forecasts.map((item, index) => (
              <Link className='item-container' key={item.id} to={`/lists/${item.id}`}>
                <div className='item-name' >
                    <>
                      {/* <FontAwesomeIcon icon={faCircle} /> */}
                      <span>{item.name}</span>
                    </>
                </div>
                <div className='quantity'>
                              <span>{item.checkedPositions} </span>
                  <span>/ {item.totalPositions} </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      </>
      );
}

  export default ShoppingList;
