import React,{useEffect, useState, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPlus, faCog } from '@fortawesome/free-solid-svg-icons';
import ClickAwayListener from 'react-click-away-listener';
import './navbar.scss';
import { useNavigate } from 'react-router-dom';

const Navbar=(props) => {
  const [newListName, setNewListName] = useState('');
  const [shareWithEmail, setShareWithEmail] = useState('');

  const [scrolled,setScrolled]=React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [popup, setPopup] = useState(false);
  const [renamePopup, setRenamePopup] = useState(false);
  const [sharePopup, setSharePopup] = useState(false);

  const navigate = useNavigate();
  const addInputRef = useRef();

  const handleAddButtonClick = () => {
    props.AddItem(inputValue);

		setInputValue('');
    addInputRef.current.focus();
	};

  const handleScroll=() => {
    const offset=window.scrollY;
    if(offset > 10 ){
      setScrolled(true);
    }
    else{
      setScrolled(false);
    }
  }

  const renameListApi = () => {
    props.RenameList(newListName);
    setRenamePopup(false);
  }

  const deleteListApi = () => {
    props.DeleteList();
    navigate("/");
  }

  const shareListApi = () => {
    props.ShareList(shareWithEmail);
    setSharePopup(false);
  }

  const addInputKeyDown = (key) => {
    if (key.key==='Enter') {
      handleAddButtonClick();
    }
  }

  useEffect(() => {
    window.addEventListener('scroll',handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  })

  //render helpers
  const renderPopup = () => {
    return <>
            {/* The popup itself */}
            {popup && (
              <ClickAwayListener onClickAway={() => setPopup(false)}>
                      <div className={'popup'}>
                        <ul>
                          <li onClick={()=>{setRenamePopup(true); setPopup(false)}}>Zmień nazwę</li>
                          <li onClick={()=>{setSharePopup(true); setPopup(false)}}>Współdziel</li>
                          <li style={{color: "red"}} onClick={()=>deleteListApi()}>Usuń listę</li>
                          <li style={{cursor: "default", height: "20px"}}></li>
                          <li onClick={()=>{setPopup(false)}}>Zamknij menu</li>
                        </ul>
                      </div>
              </ClickAwayListener>
            )} 
          </>;
  }

  const renderRenamePopup = () => {
    return <>
        {renamePopup && (
            <ClickAwayListener onClickAway={() => setRenamePopup(false)}>
                    <div className={'popup'}>
                      <ul>
                        <li><input style={{background:"white", color:'black'}} value={newListName} onChange={(event) => setNewListName(event.target.value)} className='add-item-input' placeholder='Wpisz nową nazwę listy' /></li>
                        <li onClick={()=>renameListApi()}>Zmień</li>
                        <li style={{cursor: "default", height: "20px"}}></li>
                        <li onClick={()=>{setRenamePopup(false)}}>Zamknij menu</li>
                      </ul>
                    </div>
            </ClickAwayListener>
        )}     
    </>
  }

  const renderSharePopup = () => {
    return <>
            {sharePopup && (
            <ClickAwayListener onClickAway={() => setSharePopup(false)}>
                    <div className={'popup'}>
                      <ul>
                        <li><input type='email' style={{background:"white", color:'black'}} 
                            value={shareWithEmail} onChange={(event) => setShareWithEmail(event.target.value)} 
                            className='add-item-input' placeholder='Wpisz adres e-mail' /></li>
                        <li onClick={()=>shareListApi()}>Współdziel</li>
                        <li style={{cursor: "default", height: "20px"}}></li>
                        <li onClick={()=>{setSharePopup(false)}}>Zamknij menu</li>
                      </ul>
                    </div>
            </ClickAwayListener>
        )}  
    </>
  }

  let x=['navbar'];
  if(scrolled){
    x.push('scrolled');
  }

  return (
    <header className={x.join(" ")}>
      <a style={{textDecoration: "none", color: "white"}} href='/'>
        <div className="logo">
            <FontAwesomeIcon icon={faChevronLeft}/> 
        </div>
      </a>
        <div className="logo">
            <button style={{textDecoration: "none", color: "white"}} onClick={() => setPopup(true)}><FontAwesomeIcon icon={faCog}/> </button>
        </div>
        {renderPopup()}
        {renderRenamePopup()}
        {renderSharePopup()}                    
        <div className='add-item-box'>
              <input value={inputValue} onChange={(event) => setInputValue(event.target.value)} 
                  className='add-item-input' placeholder='Wpisz pozycję do dodania' ref={addInputRef}
                  onKeyDown={(key) => addInputKeyDown(key)}/>
              <FontAwesomeIcon icon={faPlus} onClick={() => handleAddButtonClick()} />
            </div>
        
    </header>
  )

};

export default Navbar;