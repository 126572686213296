import React, { useState, useEffect, useRef } from 'react';
import '../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ListContainer, ListItem } from "../styles";
import { DragHandle } from "./DragHandle";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import {List} from '../list';
import Navbar from './navbar';
import { TokenStorage } from '../list';
import {useNavigate} from 'react-router-dom';
import RingLoader from "react-spinners/RingLoader";
import { HubConnectionBuilder } from '@microsoft/signalr';
import fetchPlus from './network/fetchPlus';

const ShoppingListPositions = (props) => {

	const navigate = useNavigate();
	const params = useParams();
	const [listId] = useState(props.id??params.id);
	const [forecasts, setForecasts] = useState([]);
	const [token] = useState(TokenStorage.getToken());
	const [loading, setLoading] = useState(true);
	const [refreshFromSignal, setRefresh] = useState(0);
	const [offline, setOffline] = useState(false);

	const [ connection, setConnection ] = useState(null);
	const stateRef = useRef();

	stateRef.current = forecasts;

	const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

	useEffect(() => {
		const populateWeatherData = async () => {
	
			const requestOptions = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json',
							'Authorization': `Bearer ${token}`} 
			  };
			  var [status,body] = await fetchPlus(`https://api.milionsmakow.pl/list/${listId}/positions`, requestOptions);
			  if (status===401) {
				TokenStorage.saveToken('');
				navigate('/auth');
				return;
			  }
			  if (status==='offline') {
				setOffline(true);
				setForecasts(JSON.parse(localStorage.getItem(`List-${listId}`)));
				setLoading(false);
			  }		
			  if (status===200) {	  
				const data = body;
				setForecasts(data.sort((a, b) => a.lp > b.lp? 1 : -1));
				localStorage.setItem(`List-${listId}`, JSON.stringify(data.sort((a, b) => a.lp > b.lp? 1 : -1)));
				setLoading(false);
				setOffline(false);
			  }
		  }
	
		  if (token==null||token==='') 
		  {
			navigate('/auth');
			return;
		  }
		  populateWeatherData();
		},[navigate, token, listId, refreshFromSignal]);	

	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			//.withUrl('http://localhost:5094/hubs/lists')
			.withUrl('https://api.milionsmakow.pl/hubs/lists')
			.withAutomaticReconnect()
			.build();
			newConnection.onclose((error) => {
				console.log('Error: ', error);
				setOffline(true);
			  });
		setConnection(newConnection);
	}, []);

	useEffect(() => {
		const toggleCompleteFromEvent = (id) => {
			let newItems = [...stateRef.current];
			for (var i=0;i<newItems.length;i++) {
				if (newItems[i].id===id) {
					newItems[i].checked = !newItems[i].checked;
					setForecasts(newItems);
				}
			}
		}

        if (connection && connection._connectionState==='Disconnected') {
            connection.start()
                .then(result => {
					connection.send('JoinGroup', listId);
                    connection.on('ReceiveMessage', message => {
                        setOffline(false);
						if (message.eventType!=='ToggleComplete') {
							setRefresh(r => r+1);
						} else {
							console.log('Light event detected', message.eventType);
							toggleCompleteFromEvent(parseInt(message.event));
						}
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection, listId, forecasts]);

	const sendMessage = async (user, message, eventType) => {
        const chatMessage = {
            Id: user,
            Event: message.toString(),
			EventType: eventType
        };
        if (connection._connectionState==='Connected') {
            try {
                await connection.send('SendMessage', chatMessage);
            }
            catch(e) {
                console.log(e);
            }
        }
        else {
            alert('No connection to server yet.');
        }
    }

	const partition = (array, filter) => {
		let pass = [], fail = [];
		array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
		return [pass, fail];
	}

	const toggleCompleteAPI= async(listId, positionId) => {
		const requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`}
		  };
		  try {
		  	await fetch(`https://api.milionsmakow.pl/list/${listId}/positions/${positionId}/toggleCheck`, requestOptions);
			setOffline(false);
		  }
		  catch (error) {
			console.log('Error: ', error);
			setOffline(true);
		  }
	  }

  	const toggleComplete = async (index) => {

		const newItems = [...forecasts];
		newItems[index].checked = !newItems[index].checked;
		setForecasts(newItems);
		List.saveList(newItems,listId);
		await toggleCompleteAPI(listId, newItems[index].id);
		sendMessage(parseInt(listId), newItems[index].id, 'ToggleComplete');
	};

	const renameList = async (name) => {
		const requestOptions = {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`},
			body: JSON.stringify({ Id: listId, Name: name })
		};
		await fetch(`https://api.milionsmakow.pl/list`, requestOptions);
	}

	const deleteList = async () => {
		const requestOptions = {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`},
		};
		const response = await fetch(`https://api.milionsmakow.pl/list/${listId}`, requestOptions);
		return [response.status, await response.json()];
	}

	const shareList = async (email) => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`},
			body: JSON.stringify({Email: email})
		};
		const response = await fetch(`https://api.milionsmakow.pl/list/${listId}/share`, requestOptions);
		return [response.status, await response.json()];
	}


	const addItem = async (name) => {
		if (!name) return;
		var list = forecasts;
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`},
			body: JSON.stringify({ position: name })
		};
		var response = await fetch(`https://api.milionsmakow.pl/list/${listId}/positions`, requestOptions);
		var item = await response.json();
		list = list.concat(item);
		sendMessage(parseInt(listId), item, 'ItemAdded');
		setForecasts(list);
	}

	const clearCompleted = async () => {
		const requestOptions = {
			method: 'DELETE',
			headers: { 'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`},
		};
		var result = await fetch(`https://api.milionsmakow.pl/list/${listId}/positions/completed`, requestOptions);
		if (result.status===200)
		{
			sendMessage(parseInt(listId), "clear completed", "ClearCompleted");
			setForecasts(partition(forecasts,(i)=>i.checked)[1]);
		}
	}

	const renderPositions = () =>
	{
		var incompleted = partition(forecasts,(item)=>item.checked)[1];
		if (incompleted.length===0) 
			return <ListContainer key="0" className='main-container'>Twoja lista jest pusta</ListContainer>;
		return <DragDropContext
					onDragEnd={(param) => {
							const srcI = param.source.index;
							const desI = param.destination?.index;
							var list = forecasts;
							if (desI!==undefined) {
								list.splice(desI, 0, forecasts.splice(srcI, 1)[0]);
								List.saveList(list);
								setForecasts(list);
							};
							var order = list.map((item) => {return item.id});
							const requestOptions = {
								method: 'POST',
								headers: { 'Content-Type': 'application/json',
											'Authorization': `Bearer ${token}`},
								body: JSON.stringify({ Ids: order })
							};
							fetch(`https://api.milionsmakow.pl/list/${listId}/positions/reorder`, requestOptions).then(() => {
								sendMessage(parseInt(listId), order.toString(), 'Reorder');
							});
							}}
				>
		<ListContainer className='main-container'>
			<Droppable droppableId="droppable-1" className='item-list'>
			{(provided, _) => (
				<div ref={provided.innerRef} {...provided.droppableProps}>
						{forecasts.map((item, index) => {
							if (item.checked) return <React.Fragment key={item.id}></React.Fragment>;
							else
							return 	<React.Fragment key={item.id}>
							<Draggable
								key={item.id}
								draggableId={"draggable-" + item.id}
								index={index}
							>
							{(provided, snapshot) => (	
								<ListItem className='item-container' key={item.id} onClick={() => toggleComplete(index)}
								ref={provided.innerRef}
								{...provided.draggableProps}
								style={{
								...provided.draggableProps.style,
								boxShadow: snapshot.isDragging
									? "0 0 .4rem #666"
									: "none",
								}}>
									<DragHandle {...provided.dragHandleProps} />
									<div className={`item-name ${item.checked?"bought":""}`}>
											<FontAwesomeIcon icon={item.checked?faCheckCircle: faCircle} /> 
											<span>{item.text}</span>
									</div>
								</ListItem>
							)}
							</Draggable>
							</React.Fragment>						
						})}
					{provided.placeholder}
				</div>
		)}
			</Droppable>
		</ListContainer>
	</DragDropContext>
	}

	const renderCompletedPosition = () => {
		var completed = partition(forecasts,(item)=>item.checked)[0];
		if (completed.length===0) return <></>;
		return 		<>
		<button className='item-name' onClick={() => clearCompleted()}>Usuń wszystkie kupione</button>
			<ListContainer key="1" className='main-container'>
			{forecasts.map((item, index) => {
					if (!item.checked) return <React.Fragment key={item.id}></React.Fragment>;
					else
					return 	<React.Fragment key={item.id}>

						<ListItem className='item-container' key={item.id} onClick={() => toggleComplete(index)}>
							<div className={`item-name ${item.checked?"bought":""}`}>
									<FontAwesomeIcon icon={item.checked?faCheckCircle: faCircle} /> 
									<span>{item.text}</span>
							</div>
						</ListItem>
					</React.Fragment>						
				})}		
			</ListContainer>
	</>
	}

	if (loading)
	return	<div className='main-container' style={{textAlign:"center"}}>
				Wczytywanie danych
				<RingLoader color={'#ec645b'} cssOverride={style} loading={loading} size={150} />
			</div>;
    return (
		<>
		<Navbar AddItem={addItem} RenameList={renameList} DeleteList={deleteList} ShareList={shareList}/>
		{(offline)?<div style={{textAlign:"center", color: "black"}}>Offline</div>:''}
		{renderPositions()}
		{renderCompletedPosition()}
		</>);
  };

export default ShoppingListPositions;
