const fetchPlus = async (url, options, attempt) => {
    let response;
    let timeout = 10000;
    var promiseTimeout = new Promise(function (fulfill, reject) {
        setTimeout(reject,timeout);
    })
    try {
        response = await Promise.race([
            fetch(url, options),promiseTimeout]);
    } 
    catch (error) {
        console.log('Error: ',error);
        if (!error) throw error;
        if (error.message === 'Timeout' 
            || error.message === 'Network request failed'
            || error.message === 'Failed to fetch') {
            if (!attempt)
                return fetchPlus(url, options, "error");
            else
                return ["offline", "offline" ];
        } else {
                throw error; // rethrow other unexpected errors
            }
    }
    try {
        const body = await response.json();
        return [response.status, body];
    } catch (e) {
            if (!attempt)
            return fetchPlus(url, options, "error");
        else
            return ["offline", "offline" ];
    }
}

export default fetchPlus;