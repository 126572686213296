import React,{useEffect, useState, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCog } from '@fortawesome/free-solid-svg-icons';
import ClickAwayListener from 'react-click-away-listener';
import { TokenStorage } from '../list';
import { useNavigate } from 'react-router-dom';
import './navbar.scss';
import { useGoogleLogout } from 'react-google-login'


const MainNavbar=(props) => {
  const [scrolled,setScrolled]=React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [popup, setPopup] = useState(false);

  const navigate = useNavigate();
  const addInputRef = useRef();

  const navigateAway = () => {
    navigate("/auth");
  }
  
  const { signOut } = useGoogleLogout({
    clientId: '172521150870-ijkutvhgrkpmjo6u7648n4su0c3g4q5e.apps.googleusercontent.com',
    onLogoutSuccess: navigateAway
  })



  const handleAddButtonClick = () => {
    props.AddItem(inputValue);

		setInputValue('');
    addInputRef.current.focus();
	};

  const handleScroll=() => {
    const offset=window.scrollY;
    if(offset > 10 ){
      setScrolled(true);
    }
    else{
      setScrolled(false);
    }
  }

  const handleLogOut = () => {
    setPopup(false);
    TokenStorage.saveToken('');
    signOut();
  }

  useEffect(() => {
    window.addEventListener('scroll',handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  })

    const addInputKeyDown = (key) => {
    if (key.key==='Enter') {
      handleAddButtonClick();
    }
  }

  let x=['navbar'];
  if(scrolled){
    x.push('scrolled');
  }

  return (
    <header className={x.join(" ")}>
        <div className="logo">
        <button style={{textDecoration: "none", color: "white"}} onClick={() => setPopup(true)}><FontAwesomeIcon icon={faCog} /> </button>
        {/* The popup itself */}
        {popup && (
            <ClickAwayListener onClickAway={() => setPopup(false)}>
                    <div className={'popup'}>
                      <ul>
                        <li style={{color: "red"}} onClick={() => handleLogOut()}>Wyloguj się</li>
                        <li style={{cursor: "default", height: "20px"}}></li>
                        <li onClick={()=>{setPopup(false)}}>Zamknij menu</li>
                      </ul>
                    </div>
            </ClickAwayListener>
        )}                    
        </div>
        <div className='add-item-box'>
              <input value={inputValue} onChange={(event) => setInputValue(event.target.value)} 
                  className='add-item-input' placeholder='Dodaj listę'  ref={addInputRef}
                  onKeyDown={(key) => addInputKeyDown(key)} />
              <FontAwesomeIcon icon={faPlus} onClick={() => handleAddButtonClick()} />
            </div>
        
    </header>
  )
};

export default MainNavbar;