import React from 'react';
import ReactDOM from 'react-dom';
//import "bootstrap/dist/css/bootstrap.min.css"
//import "./bootstrap.css"
//import "./bootstrap-theme.css"
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth from './components/auth/auth';
import ShoppingList from './components/ShoppingList'
import ShoppingListPositions  from './components/ShoppingListPositions';

import * as serviceWorker from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
      <Routes>
            <Route path="/" element={<ShoppingList />} />
                <Route path="/auth" element={<Auth />} />
                <Route path="lists" element={<ShoppingListPositions />}>
                <Route path=":id" element={<ShoppingListPositions />} />
            </Route>
        </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();