
import React, { useState, useRef, useEffect } from "react"
import { TokenStorage } from "../../list";
import { useNavigate } from 'react-router-dom';
import RingLoader from "react-spinners/RingLoader";

import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';

const clientId = '172521150870-ijkutvhgrkpmjo6u7648n4su0c3g4q5e.apps.googleusercontent.com';
const scope = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';

export default function (props) {
  let [authMode, setAuthMode] = useState("signin");
  let [loading, setLoading] = useState(false);

  let [username, setUsername] = useState("");
  let [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const passwordRef = useRef(null);

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin")
  }

  const onSuccess = async (res) => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 
          'Content-Type': 'application/json',
               },
      body: JSON.stringify({ token: res.tokenObj.id_token })
    };
    const response = await fetch('https://api.milionsmakow.pl/users/authenticateByGoogle', requestOptions);
    if (response.status===200) {
      const data = await response.json();
      TokenStorage.saveToken(data.token);
      navigate('/');
    } else {
      setPassword('');
      setErrorMessage("Niepoprawny login lub hasło");
      passwordRef.current.focus();
    }
  };

  const onFailure = (err) => {
      console.log('failed:', err);
  };

  useEffect(() => {
    const initClient = () => {
          gapi.client.init({
          clientId: clientId,
          scope: scope
        });
     };
     gapi.load('client:auth2', initClient);
 });

  const logIn = async (e) => {
    e.preventDefault();
    if (username==='' || password==='') {
      setErrorMessage('Podaj nazwę uzytkownika i hasło');
      return;
    }
    setLoading(true);
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
                 },
        body: JSON.stringify({ username: username, password: password })
      };
      const response = await fetch('https://api.milionsmakow.pl/users/authenticate', requestOptions);
      setLoading(false);
      if (response.status===200) {
        const data = await response.json();
        TokenStorage.saveToken(data.token);
        navigate('/');
      }
      if (response.status===400) {
        setPassword('');
        setErrorMessage("Niepoprawny login lub hasło");
        passwordRef.current.focus();
      }
  }

  const signIn = async (e) => {
    e.preventDefault();
    if (username==='' || password==='') {
      setErrorMessage('Podaj wymagane dane');
      return;
    }
    if (passwordRepeat==='' || password==='') {
      setErrorMessage('Hasła muszą być identyczne');
      return;
    }
    setLoading(true);
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
                 },
        body: JSON.stringify({ username: username, password: password, email: email, confirmPassword: passwordRepeat })
      };
      const response = await fetch('https://api.milionsmakow.pl/users', requestOptions);
      setLoading(false);
      if (response.status===200) {
        const data = await response.json();
        TokenStorage.saveToken(data.token);
        navigate('/');
      }
      if (response.status===400) {
        setPassword('');
        setErrorMessage("Niepoprawny login lub hasło");
        passwordRef.current.focus();
      }
  }

  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

  if (loading) {
    return <RingLoader color={'#1E90FF'} cssOverride={style} loading={loading} size={150} />
  }
  if (authMode === "signin") {
    return (
      <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Logowanie</h3>
            <div className="text-center" style={{color:"black"}}>
              Nie masz jeszcze konta?{" "}
              <span className="link-primary" onClick={changeAuthMode} style={{cursor: "pointer", color: "black", textDecoration: "underline"}}>
                Zarejestruj się
              </span>
            </div>
            <div className="form-group mt-3">
              <label>Nazwa uzytkownika</label>
              <input
//                type="email"
                className="form-control mt-1"
                placeholder="Wpisz nazwę uzytkownika"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <label>Hasło</label>
              <input
                type="password"
                ref={passwordRef}
                className="form-control mt-1"
                placeholder="Wpisz hasło"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}                
              />
            </div>
            <p className="text-center mt-2" style={{color:"red"}}>
              {errorMessage}
            </p>
            <div className="form-group mt-3">
              <button type="submit" className="btn btn-primary mt-1 form-control" onClick={(e)=>logIn(e)}>
                Zaloguj
              </button>
            </div>
            <div className="form-group mt-3" style={{textAlign: "center"}}>
              <GoogleLogin
                  clientId={clientId}
                  buttonText="Sign in with Google"
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  cookiePolicy={'single_host_origin'}
                  isSignedIn={true}
              />
            </div>
            <p className="text-center mt-2" style={{color:"black"}}>
              Nie pamiętasz <a href="/auth/forgot" style={{color:"black"}}>hasła?</a>
            </p>
          </div>
        </form>
      </div>
    )
  }

  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Zakładanie konta</h3>
          <div className="text-center" style={{color:"black"}}>
            Masz juz konto?{" "}
            <span className="link-primary" onClick={changeAuthMode} style={{cursor: "pointer", color: "black", textDecoration: "underline"}}>
              Zaloguj się
            </span>
          </div>
          <div className="form-group mt-3">
            <label>Nazwa uzytkownika</label>
            <input
              //type="email"
              className="form-control mt-1"
              placeholder="np. Jan12"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Adres e-mail</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="jan.przykładowy@email.pl"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Hasło</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Hasło"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Powtórz hasło</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Hasło"
              required
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
            />
          </div>          
          <p className="text-center mt-2" style={{color:"red"}}>
              {errorMessage}
          </p>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary mt-1 form-control" onClick={(e)=>signIn(e)}>
              Dołącz
            </button>
          </div>
          <div className="form-group mt-3" style={{textAlign: "center"}}>
              <GoogleLogin
                  clientId={clientId}
                  buttonText="Sign in with Google"
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  cookiePolicy={'single_host_origin'}
                  isSignedIn={true}
              />
            </div>          
          <p className="text-center mt-2" style={{color:"black"}}>
            Nie pamiętasz <a href="/auth/forgot" style={{color:"black"}}>hasła?</a>
          </p>
        </div>
      </form>
    </div>
  )
}