export const List = {
  getList: function (id) {
    return (
      (localStorage.getItem(`List-${id}`) &&
        JSON.parse(localStorage.getItem(`List-${id}`))) ||
      this.list
    );
  },
  saveList: (list, id) => {
    localStorage.setItem(`List-${id}`, JSON.stringify(list));
  },
};

export const TokenStorage = {
  token: null,
  getToken: function () {
    return localStorage.getItem('token');
  },
  saveToken: function (token) {
    localStorage.setItem('token',token);
  }
}
